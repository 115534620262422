import { useState, useEffect } from 'react';
import React from 'react';
import Reveal from './Reveal';
import ContactButton from './ContactButton';
import Navbar from './Navbar';
import Home from './Home';
import Rooms from './Rooms';
import Approach from './Approach';
import Carousel from './Carousel';
import ContactUs from './ContactUs';
import Map from './Map';
import Footer from './Footer';

const FirstPage = () => {
   
  return (
    <div className='relative '>
      
      <div className="relative md:border-b md:border-blue min-h-screen">
        
        <div className='border-b border-blue md:h-screen pt-10 pb-10 md:pb-0'>
          <Home />
        </div>

        <Rooms />
          <div className='mt-16 md:mt-20 md:border-t md:border-blue md:h-screen'>
        <Approach />
        </div>
      </div>
      <div className='md:-mr-24 md:-ml-24 md:bg-feedbacks md:bg-cover '>
      <Carousel />
      </div>
      <div className='  min-h-screen border-t border-blue'>


      <ContactUs />
     
      
      </div>



</div> 

   
  );
};

export default FirstPage;
