import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../images/Logo1.png";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import styled, { keyframes } from "styled-components";
import { IoReorderThreeOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { fadeIn } from "react-animations";
import Reveal from "./Reveal";

const FadeInAnimation = keyframes`${fadeIn}`;
const FadeInDiv = styled.div`
  animation: 1s ${FadeInAnimation} ease-in-out;
`;

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const location = useLocation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const CrossButton = () => {
    return (
      <FadeInDiv onClick={toggleDropdown}>
        <button>
          <RxCross1 size={"40"} color="#213472" />
        </button>
      </FadeInDiv>
    );
  };

  const ReorderThreeLines = () => {
    return (
      <FadeInDiv onClick={toggleDropdown}>
        <button>
          <IoReorderThreeOutline size={"50"} color="#213472" />
        </button>
      </FadeInDiv>
    );
  };

  return (
    <nav className="pt-8">
      <div className="md:hidden fixed top-2 right-2 z-50 flex justify-end">
        <div className="relative  ">
          {isDropdownOpen ? <CrossButton /> : <ReorderThreeLines />}
        </div>
      </div>
      <Reveal>
        <div
          className={`border mt-8 md:mt-0 md:border-b-2 border-blue flex items-center justify-between ${
            isDropdownOpen ? "hidden" : ""
          }`}
        >
          <div className="grid w-full  md:w-1/4 place-items-center place-self-center items-center md:border-r md:border-blue md:pr-8 md:pl-8 lg:pr-8 lg:pl-8">
            <div className="p-2">
              {/* Your Logo/Image */}
              <img src={Logo} alt="Your Logo" className="w-28" />
              <p className="text-blue font-bold font-serif text-2xl text-center tracking-wider">
                Diversity
              </p>
              <p className="text-blue font-extralight text-center">
                Child Care
              </p>
            </div>
          </div>

          <div className={`hidden md:font-light md:flex md:space-x-12 md:pr-2`}>
            <Link
              to="/"
              className={`text-xl ${
                location.pathname === "/" ? "text-bright-orange" : "text-blue"
              } hover:text-bright-orange`}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={`text-xl ${
                location.pathname === "/about"
                  ? "text-bright-orange"
                  : "text-blue"
              } hover:text-bright-orange`}
            >
              About Us
            </Link>
            <Link
              to="/rooms"
              className={`text-xl ${
                location.pathname === "/rooms"
                  ? "text-bright-orange"
                  : "text-blue"
              } hover:text-bright-orange`}
            >
              Rooms
            </Link>
            <Link
              to="/gallery"
              className={`text-xl ${
                location.pathname === "/gallery"
                  ? "text-bright-orange"
                  : "text-blue"
              } hover:text-bright-orange`}
            >
              Gallery
            </Link>
            <Link
              to="/contact"
              className={`text-xl ${
                location.pathname === "/contact"
                  ? "text-bright-orange"
                  : "text-blue"
              } hover:text-bright-orange`}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </Reveal>
      {/* Dropdown menu for smaller screens */}
      <div
        className={`min-h-screen fixed inset-0 overflow-hidden z-40 bg-light-cream overscroll-none text-center md:hidden ${
          isDropdownOpen ? "block" : "hidden"
        }`}
      >
        <FadeInDiv>
          <Link
            to="/"
            onClick={toggleDropdown}
            className={`block ${
              location.pathname === "/" ? "text-bright-orange" : "text-blue"
            } hover:text-bright-orange text-2xl pt-20`}
          >
            Home
          </Link>
          <Link
            to="/about"
            onClick={toggleDropdown}
            className={`block ${
              location.pathname === "/about"
                ? "text-bright-orange"
                : "text-blue"
            } hover:text-bright-orange  text-2xl pt-12`}
          >
            About Us
          </Link>
          <Link
            to="/rooms"
            onClick={toggleDropdown}
            className={`block ${
              location.pathname === "/rooms"
                ? "text-bright-orange"
                : "text-blue"
            } hover:text-bright-orange text-2xl pt-12`}
          >
            Rooms
          </Link>
          <Link
            to="/gallery"
            onClick={toggleDropdown}
            className={`block ${
              location.pathname === "/gallery"
                ? "text-bright-orange"
                : "text-blue"
            } hover:text-bright-orange text-2xl pt-12`}
          >
            Gallery
          </Link>
          <Link
            to="/contact"
            onClick={toggleDropdown}
            className={`block ${
              location.pathname === "/contact"
                ? "text-bright-orange"
                : "text-blue"
            } hover:text-bright-orange text-2xl pt-12`}
          >
            Contact Us
          </Link>
        </FadeInDiv>
      </div>
    </nav>
  );
};

export default Navbar;
