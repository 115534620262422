import React, { useState } from "react";
import { BiSolidPhoneCall } from "react-icons/bi";
import "./style.css"; // Replace with the path to your CSS file
import { Link } from "react-router-dom";

const ContactButton = () => {
  const [isVibrating, setIsVibrating] = useState(false);

  const handleButtonClick = () => {
    setIsVibrating(true);

    // After a short delay, remove the animation class to stop vibrating
    setTimeout(() => {
      setIsVibrating(false);
    }, 300); // You can adjust the duration as needed
  };

  return (
    <Link
      onClick={handleButtonClick}
      to="./contact"
      className={`${
        isVibrating ? "animate-vibrate" : ""
      } fixed right-2 bottom-2 font-sans border border-light-cream  font-light p-4 bg-blue hover:bg-bright-orange hover:text-white hover:font-normal rounded-full shadow-lg z-50`}
    >
      <BiSolidPhoneCall size={30} color="white" />
    </Link>
  );
};

export default ContactButton;
