// AIzaSyAJ-fD12TbY9lyiHknHqwe5fcHvTLHdC9A

import { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Reveal from "./Reveal";

export default function Map() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAJ-fD12TbY9lyiHknHqwe5fcHvTLHdC9A",
  });

  if (!isLoaded) return <div>Loading Map...</div>;
  return (
    <div className=" h-96 mt-16">
      <MapView />
    </div>
  );
}

function MapView() {
  const center = useMemo(
    () => ({ lat: 49.05024109133206, lng: -122.34811401547222 }),
    []
  );
  return (
    <GoogleMap
      zoom={15}
      center={center}
      mapContainerClassName="w-full h-full mt-4 rounded-xl"
    >
      <Marker position={center} />
    </GoogleMap>
  );
}
