import { useState, useEffect } from "react";
import React from "react";
import Reveal from "./Reveal";
import HomeBG from "../images/1.jpg";

const Home = () => {
  return (
    //eslint-disable-next-line

    <div className="relative md:bg-home-bkg md:h-5/6 md:w-full md:bg-fixed md:bg-no-repeat md:bg-cover md:bg-top-4">
      <Reveal>
        <div className="md:hidden ">
          <img src={HomeBG}></img>
        </div>
      </Reveal>

      <Reveal delay={0.35}>
        <div className="md:shadow-md md:absolute md:top-24 md:right-36 md:opacity-95 font-serif md:text-center text-blue tracking-widest bg-light-cream ml-4 md:p-32 md:pb-20 md:pt-20">
          <Reveal delay={0.4}>
            <p className="hidden md:block mt-0 font-sans text-xl">Welcome To</p>
            <p className="mt-12 text-6xl">Diversity</p>
            <p className="text-6xl mt-2">Child Care</p>
            <div className="font-sans text-2xl mt-10 md:mt-12 pl-2 md:pl-0 tracking-wider">
              <p className="inline">Play. </p>
              <p className="inline text-red-500">Learn. </p>
              <p className="inline text-teal-500">Grow.</p>
            </div>
          </Reveal>
        </div>
      </Reveal>
    </div>
  );
};

export default Home;
