import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

const Reveal = ({ children, delay }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const animationFadeIn = useAnimation();
    useEffect(()=>{
        if(isInView){
            animationFadeIn.start("visible");
        }

    },[isInView])
  return (
    <div
    ref={ref}>
      <motion.div
      variants={{
        hidden: {opacity:0, y:35},
        visible: {opacity:1, y:0},
      }}
      initial="hidden"
      animate={animationFadeIn}
      transition={{duration:1, delay: delay}}
      >{children}</motion.div>
    </div>
  );
};

export default Reveal;