import React, { Component } from "react";
import Reveal from "./Reveal";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa6";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div>
      <Reveal>
        <div className="hidden md:block bg-light-cream h-full">
          <div className="grid grid-cols-8 mt-12 mb-2 border border-blue">
            <div className="col-span-7 pt-4 pb-4 ml-4 place-items-center text-left text-blue tracking-wide text-sm font-extralight">
              <Reveal delay={0.1}>
                <p>
                  Copyright Diversity Childcare Center © {currentYear}. All
                  rights reserved.
                </p>
                <p>Managed & Developed by IT Simplified (UK) Ltd</p>
              </Reveal>
            </div>
            <div className="grid bg-blue w-full h-full  pt-6 pb-4">
              <div className="place-self-center ">
                <Reveal delay={0.1}>
                  <BsInstagram
                    className="inline hover:cursor-pointer"
                    color="white"
                    size={22}
                    onClick={() => {
                      try {
                        openInNewTab(
                          "https://www.instagram.com/diversity_childcare_/"
                        );
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />

                  <FaFacebookF
                    className="ml-4 inline hover:cursor-pointer"
                    color="white"
                    size={22}
                    onClick={() => {
                      try {
                        openInNewTab(
                          "https://www.facebook.com/people/Diversity-Childcare/pfbid045eUe5zfCvU4VdrcuHKBSJHgin1QmX4G2jV3Y6CeCe8EeZa1UmcZ5hutTFQh6SKkl/"
                        );
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
      <div className="md:hidden bg-light-cream h-full">
        <div className="grid grid-rows-2 mt-12 border-t border-blue">
          <div className=" pt-4 pb-4 ml-4 place-items-center text-center text-blue tracking-wide text-sm font-extralight">
            <Reveal>
              <p>
                Copyright Diversity Childcare Center © {currentYear}. All rights
                reserved.
              </p>
              <p>Managed & Developed by IT Simplified (UK) Ltd</p>
            </Reveal>
          </div>
          <div className="grid bg-blue w-full h-full  pt-6 pb-4">
            <div className="place-self-center ">
              <Reveal>
                <BsInstagram
                  className="inline hover:cursor-pointer"
                  color="white"
                  size={22}
                  onClick={() => {
                    try {
                      openInNewTab(
                        "https://www.instagram.com/diversity_childcare_/"
                      );
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />

                <FaFacebookF
                  className="ml-4 inline hover:cursor-pointer"
                  color="white"
                  size={22}
                  onClick={() => {
                    try {
                      openInNewTab(
                        "https://www.facebook.com/people/Diversity-Childcare/pfbid045eUe5zfCvU4VdrcuHKBSJHgin1QmX4G2jV3Y6CeCe8EeZa1UmcZ5hutTFQh6SKkl/"
                      );
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
