import React from "react";
import FirstPhoto from "../images/1_photo.jpg";
import SecondPhoto from "../images/2_photo.jpg";
import ThirdPhoto from "../images/3_photo.jpg";
import FourthPhoto from "../images/4_photo.jpg";
import FifthPhoto from "../images/5_photo.jpg";
import SixthPhoto from "../images/6_photo.jpg";
import SeventhPhoto from "../images/HomePageBackground1.png";

const ImageCollage = () => {
  const images = [
    FifthPhoto,
    SeventhPhoto,
    ThirdPhoto,
    FourthPhoto,
    FirstPhoto,
    SixthPhoto,
    // Add more image URLs here
  ];

  return (
    <div className="h-screen">
      <div className="h-full grid pl-4 pr-4 md:pl-0 md:pl-0 grid-rows-6 md:grid-rows-5 md:grid-cols-4 gap-6">
        <img
          src={ThirdPhoto}
          className="md:col-span-2 md:row-span-2 w-full h-full object-cover transition-transform duration-300 transform-gpu border border-blue hover:scale-105"
        />
        <img
          src={FifthPhoto}
          className="w-full h-full object-cover transition-transform duration-300 transform-gpu border border-blue hover:scale-105"
        />
        <img
          src={SixthPhoto}
          className="w-full h-full object-cover transition-transform duration-300 transform-gpu border border-blue hover:scale-105"
        />

        <img
          src={FirstPhoto}
          className="md:col-span-2 md:row-span-2 w-full h-full object-cover transition-transform duration-300 transform-gpu border border-blue hover:scale-105"
        />
        <img
          src={SecondPhoto}
          className="w-full h-full object-cover transition-transform duration-300 transform-gpu border border-blue hover:scale-105"
        />
        <img
          src={FourthPhoto}
          className="w-full h-full object-cover transition-transform duration-300 transform-gpu border border-blue hover:scale-105"
        />
      </div>
    </div>
  );
};

export default ImageCollage;
// return (
//   <div className="grid grid-cols-4 gap-8">
//     {images.map((imageUrl, index) => (
//       <div key={index} className="relative">
//         <img
//           src={imageUrl}
//           alt={`Image ${index + 1}`}
//           className="w-full h-full object-cover transition-transform duration-300 transform-gpu border border-blue hover:scale-105"
//         />
//       </div>
//     ))}
//   </div>
// );
