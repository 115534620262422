import logo from "./logo.svg";
// import './App.css';
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import Carousel from "./components/Carousel";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import ContactButton from "./components/ContactButton";

import ReactDOM from "react-dom/client";
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import FirstPage from "./components/FirstPage";
import AboutUs from "./components/AboutUs";
import RoomsPage from "./components/RoomsPage";
import Gallery from "./components/Gallery";
import NotFound from "./components/NotFound";
import { useEffect } from "react";

function App() {
  return (
    <HashRouter>
      <ScrollToTop />
      <div className="relative bg-light-cream w-screen overflow-hidden min-h-screen md:pl-12 md:pr-12 lg:pl-24 lg:pr-24">
        <ContactButton />
        <Navbar />

        <Routes>
          <Route index path="/" element={<FirstPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/rooms" element={<RoomsPage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
