import React, { useState, useEffect } from "react";
import Reveal from "./Reveal";
import FirstImage from "../images/2.jpg";
import SecondImage from "../images/3.jpg";

const AboutUs = () => {
  return (
    <div className="min-h-screen">
      <Reveal>
        <h1 className="mt-10 md:mt-16 md:mb-12 text-center text-2xl font-extralight tracking-wider text-blue">
          About Us
        </h1>
      </Reveal>
      <Reveal>
        <h1 className="mt-10 md:mt-8 text-center text-4xl font-serif tracking-wider text-blue">
          Your Child Is In Great Hands
        </h1>
      </Reveal>
      <Reveal>
        <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 md:gap-16 mt-10 md:mt-16 md:border-t md:border-blue md:pt-10 font-extralight text-blue text-center tracking-wide ">
          <div className="mb-24 md:place-self-center">
            <img className="md:h-full md:w-5/6 shadow-lg" src={FirstImage} />
          </div>
          <div className=" pr-4 pl-4 md:pr-0 md:pl-0 md:mt-16">
            <h1 className="text-blue font-serif text-4xl">Philosophy</h1>
            <p className="mt-8 font-extralight">
              The philosophy of a diversity childcare center is rooted in the
              belief that embracing and celebrating diversity is essential for
              the holistic development and well-being of young children. The
              center recognizes that each child is a unique individual with
              their own distinct background, culture, and abilities. By
              fostering an inclusive and welcoming environment, the center aims
              to create a space where every child feels valued, respected, and
              supported in their growth and learning journey.
            </p>
            <p className="mt-6 font-extralight">
              In a diversity childcare center, children are encouraged to
              explore and appreciate different cultures, languages, traditions,
              and perspectives. The curriculum is designed to expose children to
              a wide range of experiences and knowledge, promoting empathy,
              open-mindedness, and tolerance. Through diverse books, toys, and
              activities, children are introduced to various cultural practices
              and encouraged to ask questions, share their own experiences, and
              develop a sense of curiosity about the world around them.
            </p>
            <p className="mt-6 font-extralight">
              Moreover, the staff and educators at the diversity childcare
              center play a crucial role in fostering an inclusive atmosphere.
              They are trained to recognize and address any biases they may
              have, creating a safe space where all children are treated equally
              and with respect. The teachers act as facilitators, guiding
              children to embrace diversity and engage in meaningful
              interactions with their peers from different backgrounds, thus
              promoting social and emotional development.
            </p>
          </div>
        </div>
      </Reveal>
      <Reveal delay={0.3}>
        <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 mt-8  md:gap-16 md:border-t md:border-blue md:pt-10 font-extralight text-blue text-center tracking-wide">
          <div className=" pr-4 pl-4 md:pr-0 md:pl-0 md:mt-16">
            <h1 className="text-blue font-serif text-4xl">Health & Safety</h1>
            <p className="mt-8 font-extralight">
              The health and safety of every child is our top priority. We are
              committed to providing a nurturing and secure environment where
              children can learn, play, and grow with confidence. Our
              comprehensive approach to health and safety ensures that every
              child's well-being is safeguarded, regardless of their background,
              culture, or abilities.
            </p>
            <p className="mt-6 font-extralight">
              Our center follows strict health protocols to maintain a clean and
              hygienic environment. We regularly sanitize all surfaces, toys,
              and equipment to prevent the spread of germs and maintain a
              healthy space for the children. Additionally, we promote proper
              handwashing habits among both children and staff, as it is one of
              the most effective ways to prevent illness. We have well-equipped
              first aid kits on-site, and our staff members are trained in CPR
              and first aid to handle any emergencies promptly and efficiently.
            </p>
            <p className="mt-6 font-extralight">
              Furthermore, we take food allergies and dietary preferences
              seriously, acknowledging that diverse backgrounds may lead to
              varying dietary requirements. Our center works closely with
              parents to create individualized meal plans that cater to each
              child's specific needs, ensuring they receive nutritious and safe
              meals while at our facility.
            </p>
          </div>
          <div className="row-start-1 md:col-start-2 md:place-self-center">
            <img
              className="h-5/6 md:h-full md:w-full  shadow-lg "
              src={SecondImage}
            />
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default AboutUs;
