import React, { Component } from "react";
import ImageCollage from "./ImageCollage";

const Gallery = () => {
  return (
    <div className="mt-16 h-1/2">
      <h1 className="text-4xl text-center mt-4 mb-10 p-6 text-blue font-serif">
        Gallery
      </h1>
      <ImageCollage />
    </div>
  );
};

export default Gallery;
