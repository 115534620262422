// components/NotFound.js
import React from "react";

const NotFound = () => {
  return (
    <div className="mt-36 mb-36 flex items-center justify-center bg-light-cream">
      <div className=" p-4">
        <h1 className="text-6xl text-blue font-semibold mb-4">
          404 - Page Not Found
        </h1>
        <p className="text-blue">
          Oops! The page you are looking for does not exist.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
