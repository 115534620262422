import React, { useEffect, useState } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";
import Reveal from "./Reveal";

const ShowFeedbacks = ({ feedbacks }) => {
  const numberFeedbacks = feedbacks.length;

  const [currentFeedback, setCurrentFeedback] = useState(0);
  const [currentFeedbackCont, setCurrentFeedbackCont] = useState([]);

  const getCurrentFeedback = () => {
    if (currentFeedback >= 0 && currentFeedback < numberFeedbacks) {
      return feedbacks[currentFeedback];
    }
    return null; // Return null if currentFeedback is out of range
  };

  useEffect(() => {
    setCurrentFeedbackCont(getCurrentFeedback);
  }, [currentFeedback, feedbacks]);

  const showNext = () => {
    const next = currentFeedback + 1;
    if (next < numberFeedbacks) setCurrentFeedback(next);
  };

  const showPrev = () => {
    const prev = currentFeedback - 1;

    if (prev >= 0) {
      setCurrentFeedback(prev);
    }
  };

  return (
    <div className="relative border-t border-blue md:border-0 pt-14 mt-20 mb-14 md:mb-0 md:mt-0 md:grid relative md:h-3/4 md:p-28 md:pb-32">
      {currentFeedbackCont}
      {/* Buttons for larger screen */}
      <div className="hidden md:block">
        <div className="absolute top-1/2 left-52 cursor-pointer">
          <GrPrevious onClick={showPrev} size={38} color="#213472" />
        </div>
        <div className="absolute top-1/2 right-52 cursor-pointer">
          <GrNext onClick={showNext} size={38} color="#213472" />
        </div>
      </div>

      {/* Buttons for smaller screen */}
      <div className="absolute md:hidden top-1/2 left-2 cursor-pointer">
        <GrPrevious onClick={showPrev} size={20} color="#213472" />
      </div>
      <div className="absolute md:hidden top-1/2 right-2 cursor-pointer">
        <GrNext onClick={showNext} size={20} color="#213472" />
      </div>
    </div>
  );
};

const Carousel = () => {
  const [feedbacks, setFeedbacks] = useState([]);

  const GetFeedbacks = async () => {
    const APIRqst =
      "https://us-central1-diversitychildcarewebsite.cloudfunctions.net/getReviews";
    try {
      const response = await fetch(APIRqst);
      const data = await response.json();
      if (data.result && data.result.reviews) {
        const reviews = data.result.reviews.map((review, index) => (
          <div
            key={index}
            className="place-self-center text-center pl-10 pr-10 md:pl-0 md:pr-0 md:w-1/2 md:left-1/4"
          >
            <h1 className="text-4xl text-blue font-serif tracking-wider">
              What Parents Think
            </h1>
            <p className="mt-10 md:mt-20 text-md font-extralight text-blue tracking-wid">
              {review.text}
            </p>
            <p className="mt-8 md:mt-16 text-md text-blue font-serif tracking-wide">
              Google Review By: {review.author_name}
            </p>
          </div>
        ));
        setFeedbacks(reviews);
      } else {
        console.log("No reviews found.");
      }
    } catch (error) {
      console.log("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    GetFeedbacks(); // Call GetFeedbacks here to fetch feedbacks
  }, []);

  return (
    <Reveal delay={0.35}>
      <ShowFeedbacks feedbacks={feedbacks} />
    </Reveal>
  );
};

export default Carousel;
